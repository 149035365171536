import Link from "next/link";
import Image from "next/image";
import stardeosLogo from "../../../public/assets/images/stardeos02.png";
import Ads from "@components/ads/ads.component";

export default function Error({statusCode, title}:any){
    return(
        <div className = "at-wrapper at-background at-error-page">
            <Link href="/">
                <a>
                    <div className="error-image">
                        <Image
                            src={stardeosLogo}
                            alt="Stardeos"
                            width = {205}
                            height = {136}
                            quality={100}
                        />
                    </div>
                </a>
            </Link>
            <div className = "error-content" >
                {
                    window.screen.width > 1000 && <Ads type="banner" />
                }
                <div style={{ display: 'flex', justifyContent: 'center', marginInline: "10%" }}>
                    <h1>{statusCode} {title ?? ""}</h1>
                    <p style={{ maxWidth: "70%", lineHeight: 1.5 }}> Estamos teniendo problemas para llevarte a esa página, por favor actualiza la página hasta que te deje entrar.</p>
                </div>
                {
                    window.screen.width > 1000 && <Ads type="banner" />
                }
            </div>
            <Ads type="block" />
            

        </div>
        
    );
}