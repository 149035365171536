
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React, { useState, useEffect } from "react";
import { Banner } from "@components/banner/banner.component";
import { channelService } from "@services/channel";
import VideoList from "@components/video-list/video-list.component";
import { usePagination } from "@hooks/usePagination";
import Ads from "@components/ads/ads.component";
import { useRouter } from "next/router";
import Error from "@components/error";
import ChannelLayout from "@layouts/channel.layout";

function Channel() {
  const { query } = useRouter();
  const channelId: any = query.channel;

  const [channel, setChannel] = useState<any>(null);
  const [errorStatus, setErrorStatus] = useState<any>(false);

  const [videos, { nextPage, error, next, loading }] = usePagination(
    channelService.getChannelVideos,
    "videos",
    [channelId],
    {}
  );

  useEffect(() => {
    if (channelId) {
      channelService.getChannel(channelId)
        .then(({ data }) => {
          setChannel(data);
        })
        .catch(({ response }) => {
          const { data } = response;
          setErrorStatus(data.statusCode);
        })
    }
  }, [channelId]);

  if (errorStatus) {
    return <Error statusCode={errorStatus} />
  }

  return (
    channel && (
      <ChannelLayout
        channel={channel}
      >
        <div className="channel-page">
          <div className="at-banner at-channel-banner" style={{ backgroundColor: "#0E1121"}}>
            <Banner channel={channel} />
          </div>
          <Ads type="horizontal" />
          <main className="at-main">
            <VideoList
              videos={videos}
              next={next}
              loading={loading}
              nextPage={nextPage}
              error={error}
              channel={true}
            />
          </main>
          <style jsx>{`
              body {
                background-color: #0e1121;
              }

            .channel-page {
              width: calc(100% - 350px);
              height: 100%;
              padding-top: 25px;
              background: #0E1121;
            }

            .at-banner {
              margin-bottom: 0px !important;
            }
          `}</style>
        </div>
      </ChannelLayout>
    )
  );
}

const __Page_Next_Translate__ = Channel;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  