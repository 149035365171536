import React, {useEffect, useState} from "react";
import {ProfilePictureComponent} from "@components/shared-components/profile-picture/profile-picture.component";
import {LoginModal} from "@components/common/loginPopup.component";
import { numberWithSufix, numberWithCommas } from "@hooks/functions";
import useTranslation from "next-translate/useTranslation";
import SubscriptionButton from "@components/subscription/subscription-button.component";
import {CustomButton} from "@components/custom-components/customButtons";
import router from "next/router";

interface IProps {
  channel: any;
  isStream: boolean;
}

const AdsenseAd = () => (

  <div className="adsense-ad" onClick={() => window.location.href = 'https://ads.stardeos.com/'}>

    <div>
      ¡Anuncia <b>LO QUE QUIERAS</b>!
      <br/>A partir de 5€-10,000 visitas. ¡Precios locos de salida!
      <br/>👇 <b>CLIC AQUÍ</b> 👇
      <br/><u><b>http://ads.stardeos.com/</b></u>
    </div>


  </div>

)

const AdsenseAdList = ({testHeight}: any) => {

  if (testHeight != undefined) {
    let ads = []

    for (let x = 0; x < ((testHeight - 500) / 500); x++) {
      ads.push(AdsenseAd())
    }

    return (<div>{ads}</div>)
  } else {

    return (<div></div>)

  }


}

export const UserBoxComponent: React.FC<IProps> = ({channel, ads, isStream}: any) => {
  const {t} = useTranslation("common");
  const [login, setLogin] = useState(false);
  const [tempFollowing, setTempFollowing] = useState<number>(0);
  const [testHeight, setTestHeight] = useState<number>(0)
  useEffect(() => {
    let _h = document.querySelector(".ads-container")?.clientHeight

    if (_h) setTestHeight(_h)


  })
  return (
    <React.Fragment>
      {login && <LoginModal toggle={() => setLogin(!login)} default={true}/>}
      <div className="at-userbox">
        <div className="at-contentbox">
          <div
            className="at-banner"
            style={
              channel?.user?.banner
                ? { backgroundImage: `url(${channel.user.banner})` }
                : { backgroundColor: "#0E1121" }
            }
          ></div>
          <div className="at-userimage">
            <ProfilePictureComponent
              img={channel?.user?.avatar}
              initials={channel?.user?.username}
              absolute={true}
              size={120}
            />
          </div>
          <h3>
            <i style={{ display: "flex", marginRight: "3px"}}>
              <img src="/assets/images/star.svg" alt="star" width="15" height="15"/>
            </i> &nbsp;
            {channel?.user?.username}
          </h3>
          <div className="followers">
            {numberWithSufix(
                (channel?.subscriberCount || 0) + tempFollowing
            )}
            &nbsp;
            {t("videoDetail.followers")}
            {" "} • {numberWithSufix(
                (channel?.user?.subscriptionCount || 0)
            )}
            &nbsp;
            {t("videoDetail.subscribers")}
          </div>
          {/* <div className="at-description">
            <p>{channel?.description}</p>
          </div> */}
        </div>
        <SubscriptionButton {...{channel}} {...{setTempFollowing}} />


        {
          isStream ?
            (<div style={{textAlign: "center", marginTop: 30, marginBottom: 40}}><h4>¡Compra Stardusts para extender el tiempo del Stream! Cuando llegue a 0:00, ¡el stream se acabará! Da vida a tu Streamer favorito donándole Stardusts</h4>
              <div style={{marginTop: 20}}></div>
              <CustomButton
                class="button primary"
                label={"Comprar stardusts"}
                loading={false}
                disabled={false}
                type="button"
                onClick={() => {
                  window.open('/buy/buy-stardust')
                }}
              />
            </div>) :
            (<div className="ads-container">
              <AdsenseAdList testHeight={testHeight}/>
            </div>)

        }

      </div>

      <style jsx global>{`

        .ads-container {

          height: 100%; 
          width: 320px;
          justify-content: center;
          align-items: center;

        }

        .adsense-ad {

          width: 320px;
          height: 480px;
          margin-top: 10%;
          background: linear-gradient(45deg, #FF5093, #9A37E7);
          display: flex;
          align-items: center;
          color: white;
          letter-spacing: 0.5px;
          font-size: 15px;
          text-align: center;
          border-radius: 8px;

          .ad-container {

            position: absolute;

          }

        }

        .at-userbox {
          padding: 0 30px;
          height: 100%;
          width: 350px;
          background-color: #0E1121;
          border-radius: 8px;
          --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
            
          @media screen and (max-width: 1000px) {
              display: none;
          }
            
          @media screen and (max-width: 600px) {
            flex-direction: column !important;
            padding-bottom: 10px !important;
          }

          .at-contentbox {
            margin-bottom: 10px;
            padding: 0px;
            background-color: transparent;

            h3 {
              color: #FFFFFF;
              display: flex;
              justify-content: center;
              i {
                margin-right: 3px;
                display: flex;
              }
            }

            .at-banner {
              margin-bottom: -30px;
              padding-top: 130px;
            }
          }
        }

        .at-btnarea {
          gap: 10px;
          flex-direction: column;
          width: 100%;

          .button {
            font-size: 12px !important;
          }
        }

        h3 {
          font-weight: 500 !important;
          font-family: var(--primary-font) !important;
          font-size: 15px !important;
          margin-bottom: 5px !important;
        }

        .followers {
          font-family: var(--primary-font);
          margin-bottom: 5px;
          width: 83%;
          font-size: 12px;
          background: #1F243E;
          display: inline-block;
          padding: 5px 20px;
          margin-bottom: 5px;
          border-radius: 18px;
          font-weight: 700;
          color: white;
          -webkit-font-smoothing: antialiased;
          text-shadow: 1px 1px 2px #0000001a;
        }

        .at-userimage {
          .at-themeimage {
            font-size: 23px;
            height: 90px;
            width: 90px;
            background-color: #0E1121;
            
            img {
              border-radius: 50%;
              border: 10px solid #0E1121;
            }
          }
        }

        .at-description {
          * {
            color: black !important;
            font-weight: 400 !important;
            font-family: var(--primary-font) !important;
            font-size: 13px !important;
            line-height: 16px !important;
          }
        }
      `}</style>
    </React.Fragment>
  );
};
