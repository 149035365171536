import { UserBoxComponent } from "@components/banner/userbox/userbox.component";
import React from "react";
import VideoLayout from "./video.layout";

export default function ChannelLayout({children, channel, isStream}: any){

  return(

    <VideoLayout

      title={channel?.displayName}
      description={"description"}
      path={channel?.displayName}
      image={channel?.user?.avatar}

    >

      <div className = "at-channel-content">

        <div>
          <UserBoxComponent channel={channel} isStream={isStream} />
        </div>

        {children}

      </div>

      <style jsx global>{`
        .at-userbox {
          width: 320px;
        }

        .at-channel-content {
          width: 100%;
          display: flex;
        }

      `}</style>

    </VideoLayout>

  )

}